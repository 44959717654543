/*
 ___           _
|_ _|_ __   __| | _____  __
 | || '_ \ / _` |/ _ \ \/ /
 | || | | | (_| |  __/>  <
|___|_| |_|\__,_|\___/_/\_\

*/

.slider-index {
  width:100%;
  overflow:hidden;
  position:relative;
  max-height:em(650) !important;
  &.content-page{
    max-height:em(450) !important;
  }
  .slide{
    position:relative;
    max-height:650px !important;
    .text-block{
      position:absolute;
      height:auto;
      top: calc(50% - 135px);
      margin:0 25%;
      width:50%;
      background-color:rgba($dark-color,0.75);
      z-index:100;
      text-align:center;
      &.no-btn{
        padding:em(55);
        h1{margin-top:0;}
        p{margin-bottom:0;}
      }
      h1,h2{
        font-size:$size-mega;
        font-weight:$fw-bold;
        color:$light-color;
        text-transform:none;
        margin-bottom:0;
      }
      p.subtitle{
        color:$light-color;
        font-weight:$fw-medium;
        font-size:$size-larger;
        text-align:center;
        &.small{
          font-size:$size-regular;
          margin-top:0.5em;
        }
      }
      .btn{
        position:relative;
        bottom:em(-10);
        padding:1.5em 2em;
        .white-triangle{
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8.5px 0 8.5px 13px;
          border-color: transparent transparent transparent $light-color;
          display:inline-block;
          margin-left:em(14);
          position:relative;
          top:2px;
        }
      }
    }
  }
}
.section-solution{
  .title{
    text-align:center;
    font-size:$size-mega;
    margin:1em 0 0;
  }
  .subtitle{
    text-align:center;
    font-size:$size-regular;
    margin-bottom:0;
  }
  .slider-solution{
    width:100%;
    position:relative;
    height:400px;
    .slick-slider-solution,.slick-list,.slick-track{height:300px;}
    .slick-dots{
      bottom:-50px;
      li{
        button {
          background:rgba($colorful03,0.4);
          &:before{content:"";}
        }
        &.slick-active{
          button{background:$colorful03;}
        }
      }
    }
    .slide{
      text-align:center;
      height:100%;
      img{
        max-width:80%;
        margin:0 10%;
      }
    }
  }
}
.company{
  position:relative;
  .text-section{
    z-index:2;
    position:relative;
    .inside-text-section{
      &.left{
        color:$light-color;
        padding-right:em(80);
        position:relative;
        background:url("/assets/img/frontend/backgrounds/carton-bg.jpg");
        &::before{
          content:"";
          position:absolute;
          width:800px;
          height:100%;
          background:url("/assets/img/frontend/backgrounds/carton-bg.jpg");
          background-size:cover;
          left:-800px;
          top:0px;
        }
        h3{
          color:$light-color;
          font-size:$size-larger;
        }
        p{
          font-size:$size-medium;
          &.partenaire{
            text-transform:uppercase;
            margin:1.5em 0 0.5em;
            font-size:$size-regular;
            font-weight:$fw-bold;
            letter-spacing:0.1em;
          }
        }
      }
      &.right{
        padding-left:em(150);
        background-color:$lightgrey;
        position:relative;
        &::after{
          content:"";
          position:absolute;
          width:800px;
          height:100%;
          background-color:$lightgrey;
          right:-800px;
          top:0px;
        }
        h3{font-size:$size-larger;}
        ul{
          list-style-type:circle;
          li{
            line-height:1.6em;
            font-size:$size-regular;
          }
        }
      }
    }
  }
}
.echantillon{
  background-color:$colorful04;
  position:relative;
  padding:em(40);
  .container{
    z-index:1;
    position:relative;
    .mascotte-bulle{
      width: 200px;
      max-width: 100%;
    }
    h3,p{color:$light-color;}
    h3{
      margin-top:0;
      &.left{
        font-size:$size-big;
        font-weight:$fw-bold;
      }
      &.right{
        font-size:$size-med-large;
        font-weight:$fw-medium;
        line-height:1.5em;
        text-transform:none;
        span{
          font-size:$size-large;
          font-weight:$fw-bold;
          text-transform:uppercase;
        }
      }
    }
    p.subtitle{
      font-size:$size-medium;
      margin-bottom:0.3em;
    }
    a.phone{
      color:$colorful;
      font-size:$size-larger;
      font-weight:$fw-xbold;
    }
  }
  .carton-right{
    position:absolute;
    right:0;
    top:0;
    height:100%;
    z-index:0;
  }
}
.environnement{
  position:relative;
  p.engagement{
    font-style:italic;
    text-align:center;
    font-size:$size-big;
    font-weight:$fw-bold;
    color:$light-color;
    margin-bottom:0.3em;
    span.white-bar{
      display:inline-block;
      width:80px;
      height:3px;
      background-color:$light-color;
      position:relative;
      bottom:10px;
      margin:0 15px;
    }
  }
  h3.respect{
    font-size:$size-mega;
    letter-spacing:0.1em;
    text-align:center;
    color:$light-color;
    font-style:italic;
    margin-top:0;
    font-weight:$fw-medium;
  }
  .fsc-box{
    background-color:rgba($colorful04,0.75);
    padding:em(45) 0 0;
    width:50%;
    margin:0 auto;
    text-align:center;
    p{
      color:$light-color;
      letter-spacing:0.2em;
      text-align:center;
      font-size:$size-medium;
      font-weight:$fw-bold;
    }
    img{
      display:block;
      margin:0 auto;
      padding:15px;
      max-width:100px;
      background-color:$light-color;
    }
    .btn{
      position:relative;
      bottom:-20px;
      padding:1em 2em;
      text-transform:uppercase;
      margin-top:1em;
    }
  }
}
