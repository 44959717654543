/*
 _           _   _
| |__  _   _| |_| |_ ___  _ __  ___
| '_ \| | | | __| __/ _ \| '_ \/ __|
| |_) | |_| | |_| || (_) | | | \__ \
|_.__/ \__,_|\__|\__\___/|_| |_|___/

*/

.actions{
  margin-top: 2em;
}

// Buttons
.btn, input[type="button"], input[type="submit"], button {
  -webkit-font-smoothing: antialiased;
  @include transition-all($base-time);
  position: relative;
  cursor: pointer;
  background: none;
  border: $border-btn-size solid $light-color;
  color: $light-color;
  display: inline-block;
  font-size: 1em;
  font-family: $typo;
  font-weight: $fw-xbold;
  line-height: 1em;
  padding: 0.8em 1.4em;
  text-align: center;
  text-decoration: none;
  background: none;
  vertical-align: top;
  margin-bottom: 4px;
  outline: none;
  i.ico-plus{
    position: relative;
    position: absolute;
    right: 15px;
    top: 24%;
    display: inline-block;
    vertical-align: middle;
    font-size: 1.5em;
    margin-left: 1em;
  }
  &.btn-ico{
    padding-right: 2.75em !important;
  }
  &.btn-fill {
    display: block;
    width: 100%;
  }
  &.xsmall{
    font-size: $size-xsmall;
    &, i{
      font-weight: $fw-xbold;
      font-style: normal;
    }
  }
  &.smaller{
    font-size: $size-smaller;
    &, i{
      font-weight: $fw-xbold;
      font-style: normal;
    }
  }
  &.small{
    font-size: $size-small;
    &, i{
      font-weight: $fw-xbold;
      font-style: normal;
    }
  }
  &.medium{
    font-size: $size-medium;
  }
  &.large{
    font-size: $size-medium;
    padding: 0.8em 1.7em;
  }
  &.larger{
    font-size: $size-larger;
  }
  &.big{
    font-size: $size-big;
  }
  &.ultra{
    font-size: $size-ultra;
  }
  &.mega{
    font-size: $size-mega;
  }
  &.giga{
    font-size: $size-giga;
  }
  &:hover, &:focus {
    border-color: $btn-color;
    background: $btn-color;
    color: $fill-btn-color;
  }
  &.color-fill{
    border-color: $btn-color;
    background: $btn-color;
    color: $fill-btn-color;
    &:hover, &:focus {
      border-color: saturate(darken($btn-color, 6%),15%);
      background: saturate(darken($btn-color, 6%),15%);
      color: $fill-btn-color;
    }
    &.color-light{
      border-color: $btn-color-light;
      background: $btn-color-light;
      color: $fill-btn-color-light;
      &:hover, &:focus {
        border-color: $fill-btn-color-light;
        background: $fill-btn-color-light;
        color: $btn-color-light;
      }
    }
    &.color-dark{
      border-color: $fill-btn-color-light;
      background: $fill-btn-color-light;
      color: $btn-color-light;
      &:hover, &:focus {
        border-color: $btn-color-light;
        background: $btn-color-light;
        color: $fill-btn-color-light;
      }
    }
    &.light-to-colorful{
      border-color: $btn-color-light;
      background: $btn-color-light;
      color: $fill-btn-color-light;
      &:hover, &:focus {
        border-color: $btn-color;
        background-color: $btn-color;
        color: $btn-color-light;
      }
    }
    &.dark-to-colorful{
      border-color: $dark-color;
      background: $dark-color;
      color: $btn-color-light;
      &:hover, &:focus {
        border-color: $btn-color;
        background-color: $btn-color;
        color: $btn-color-light;
      }
    }
    &.light-to-colorful02{
      border-color: $btn-color-light;
      background: $btn-color-light;
      color: $fill-btn-color-light;
      &:hover, &:focus {
        border-color: $btn-color02;
        background-color: $btn-color02;
        color: $btn-color-light;
      }
    }
    &.light-to-colorful03{
      border-color: $btn-color-light;
      background: $btn-color-light;
      color: $fill-btn-color-light;
      &:hover, &:focus {
        border-color: $btn-color03;
        background-color: $btn-color03;
        color: $btn-color-light;
      }
    }
    &.colorful02-to-dark{
      border-color: $btn-color02;
      background-color: $btn-color02;
      color: $btn-color-light;
      &:hover, &:focus {
        border-color: $fill-btn-color-light;
        background: $fill-btn-color-light;
        color: $btn-color-light;
      }
    }
    &.colorful03-to-dark{
      border-color: $btn-color03;
      background-color: $btn-color03;
      color: $btn-color-light;
      &:hover, &:focus {
        border-color: $fill-btn-color-light;
        background: $fill-btn-color-light;
        color: $btn-color-light;
      }
    }
    &.dark-to-colorful02{
      border-color: $fill-btn-color-light;
      background: $fill-btn-color-light;
      color: $btn-color-light;
      &:hover, &:focus {
        border-color: $btn-color02;
        background-color: $btn-color02;
        color: $btn-color-light;
      }
    }
    &.grey-to-colorful02{
      border-color: $darkgrey;
      background: $darkgrey;
      color: $btn-color-light;
      &:hover, &:focus {
        border-color: $btn-color;
        background-color: $btn-color;
        color: $btn-color-light;
      }
    }
    &.dark-opa-to-full{
      border: none;
      background: rgba($fill-btn-color-light, 0.5);
      color: $btn-color-light;
      &:hover, &:focus {
        border-color: $btn-color-light;
        background: $btn-color-light;
        color: $fill-btn-color-light;
      }
    }
    &.light-clear{
      background: none;
      border: 2px solid #FFF;
      &:hover, &:focus{
        background: #FFF;
        color: $colorful;
      }
    }
  }
  &.tab {
    border-color: $btn-color;
    background: $btn-color;
    color: $fill-btn-color;
    border-bottom: none !important;
    border-radius: 10px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    line-height: 1em !important;
    &.light-clear{
      background: none;
      border: 2px solid #FFF;
      &:hover, &:focus{
        background: #FFF;
        color: $colorful;
      }
    }
    &.hovered {
      background: #FFF;
      color: $colorful;
    }
  }
  span{
    &.clear{
      display: block;
      clear: both;
    }
    &.text-left{
      float: left;
    }
    &.text-right{
      float: left;
    }
    &.ico{
      margin: 4px 0 0 4px;
      width: 15px;
      height: 15px;
      display: block;
    }
    &.label{
      position: absolute;
      top: 0;
      right: 0;
      height: 111%;
      height: calc(100% + 5px);
      padding: 0 15px 0 12px;
      margin-top: -2px;
      margin-right: -2px;
      margin-bottom: -2px;
      @include transition-all($base-time);
      &.right{
        display: inline-block;
        vertical-align: middle;
        background: $colorful;
        border: $border-size solid $colorful;
        -webkit-border-top-right-radius: $border-radius-round;
        -webkit-border-bottom-right-radius: $border-radius-round;
        -moz-border-radius-topright: $border-radius-round;
        -moz-border-radius-bottomright: $border-radius-round;
        border-top-right-radius: $border-radius-round;
        border-bottom-right-radius: $border-radius-round;
        overflow: hidden;
      }
    }
    i.number{
      position: relative;
      font-style: normal;
      display: inline-block;
      font-size: $size-medium;
      font-weight: $fw-bold;
      top: 30%;
    }
  }
  &.ico-left{
    span.ico{
      float: left;
    }
  }
  &.ico-right{
    span.ico{
      float: right;
    }
  }

  &.label-btn-right{
    padding-right: 3.5em !important;
    &:hover, &:focus{
      color: $light-color !important;
    }
  }
}
