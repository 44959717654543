@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,900italic,300,300italic,400italic,700,700italic,900);


body, *{
  font-family: $typo;
  font-weight: $fw-normal;
}

h1, h2, h3, h4, h5, h6{
  color: $dark-color;
  text-transform: uppercase;
  font-weight: 700;
  font-family: $titleTypo;
  line-height: 1.15em;
}

p, span{
  font-weight: $fw-normal;
  font-family: $typo;
}

a{
  color: $base-color;
  text-decoration: none;
  &:hover, &:focus{
    color: $colorful;
  }
}

h1, .h1{ font-size: 2em;}
h2, .h2{ font-size: 1.6em;}
h3, .h3{ font-size: 1.25em;}
h4, .h4{ font-size: 1.1em;}
h5, .h5{ font-size: 1em;}
h6, .h6{ font-size: 0.9em;}


/* Font weights sizes */
.fw-xlight{
  font-weight: $fw-xlight;
}
.fw-light{
  font-weight: $fw-light;
}
.fw-normal{
  font-weight: $fw-normal;
}
.fw-medium{
  font-weight: $fw-medium;
}
.fw-bold, strong, b{
  font-weight: $fw-bold;
}
.fw-xbold{
  font-weight: $fw-xbold;
}

/* Font sizes classes */
.size-xsmall{
  font-size: $size-xsmall;
}
.size-smaller{
  font-size: $size-smaller;
}
.size-small{
  font-size: $size-small;
}
.size-medium{
  font-size: $size-medium;
}
.size-large{
  font-size: $size-large;
}
.size-larger{
  font-size: $size-larger;
}
.size-big{
  font-size: $size-big;
}
.size-ultra{
  font-size: $size-ultra;
}
.size-mega{
  font-size: $size-mega;
}
.size-giga{
  font-size: $size-giga;
}
