/*
 _   _                _
| | | | ___  __ _  __| | ___ _ __
| |_| |/ _ \/ _` |/ _` |/ _ \ '__|
|  _  |  __/ (_| | (_| |  __/ |
|_| |_|\___|\__,_|\__,_|\___|_|

*/

.desktop-only{display:block;}
.mobile-only{display:none;}
.tablet-only{display:none;}

header#main-header {
  background: $bg-header;
  position:fixed;
  width:100%;
  top:0;
  z-index:999;
  box-shadow: 0 4px 2px -2px rgba($dark-color,0.3);
  .container{
    width:90%;
    padding:0;
  }
  &.opened{
    .top-bar{
      .mobile-menu-icon{
        width:45px;
        -ms-transform: rotate(270deg); /* IE 9 */
        -webkit-transform: rotate(270deg); /* Chrome, Safari, Opera */
        transform: rotate(270deg);
      }
    }
    .right-color{display:none;}
    .bottom-bar{
      position:fixed;
      top:95px;
      width:100%;
      height:100%;
      visibility:visible;
      max-height:700px;
      .container{width:100%;}
      .left-triangle{display:none !important;}
      nav#main-nav .nav .right-block ul.socials{display:none;}
    }
  }
  .top-bar{
    height:em(95);
    width:100%;
    background-color:$light-color;
    position:relative;
    .mobile-menu-icon{
      width:64px;
      position:absolute;
      right:30px;
      display:none;
      height:100%;
      @include transition-all(300ms);
      span.bars{
        width:100%;
        height:3px;
        background-color:#000;
        margin-bottom:5px;
        display:block;
        &:last-child{margin-bottom:0;}
      }
    }
    .logo {
      margin: em(15) auto 0;
      float: left;
      a{
        display: block;
        padding:0 0.95em;
        img{
          width: 100%;

        }
      }
    }
    .quote-header{
      float: left;
      margin-top: 2.3em;
      margin-left: 1%;
      padding-left: 1%;
      border-left: gray solid 1px;
      width:29%;
      display:block;
      line-height:14px;
      font-size:16px;
      text-transform:none;
      font-weight:$fw-light;
    }
    .contact-section{
      float:right;
      .phone{
        display:inline-block;
        font-weight:$fw-light;
        color:$dark-color;
        line-height:1.4em;
        font-size:$size-med-large;
        text-align:right;
        margin:0.6em 0;
        span{
          font-weight:$fw-xbold;
          color:$colorful;
          font-size:$size-large;
        }
      }
      .btn{
        margin:1.05em 0 1.05em em(25);
        i.icons.letter{margin-left:em(15);}
      }
    }
  }
  .bottom-bar{
    height:em(55);
    display:block;
    width:100%;
    overflow:hidden;
    position:relative;
    @include transition-all(500ms);
    .right-color{
      width:20%;
      height:100%;
      background: $lightgrey;
      position:absolute;
      right:0px;
      z-index:0
    }
    .container{
      z-index:1;
      background-color:$bg-header;
      position:relative;
    }
    nav#main-nav {
      display:block;
      width:100%;
      z-index:2;
      position:relative;
      .nav{
        $nav-spacer: 1.5em;
        $link-padder: 0.25em;
        width:100%;
        .left-block{float:left;}
        ul.main {
          position: relative;
          margin: 0;
          padding: 0;
          display: inline-block;
          list-style-type: none;
          // For IE, the outcast
          zoom:1;
          *display: inline;
          li {
            float: left;
            text-align: center;
            a {
              display: block;
              text-decoration: none;
              color: $light-color;
              font-weight: $fw-bold;
              font-size:$size-regular;
              padding: 0.95em $link-padder*3;
              &:hover, &:focus {
                color: $dark-color;
                background-color:$light-color;
              }
            }
          }
        }
        .right-block{
          float:right;
          .green-menu{
            display:inline-block;
            height:em(55);
            float:left;
            position:relative;
            right:-15px;
            padding-left:42px;
            .left-triangle{
              position:absolute;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 0 55px 42px;
              border-color: transparent transparent $colorful transparent;
              left:0px;
              top:0px;
            }
            .green-block{
              background-color:$colorful;
              ul li{
                a{
                  color:$dark-color;
                  &:hover,&:focus{
                    background:none;
                    color:$light-color;
                  }
                }
                &:first-child{
                  margin: 0 $link-padder;
                  a{padding: 0.95em $link-padder*3;}
                }
              }
            }
          }
          ul.socials {
            list-style: none;
            display:inline-block;
            margin:0;
            padding: 0 0 0 $nav-spacer;
            background: $lightgrey;
            height:em(55);
            padding:0 15px 0 42px;
            position:relative;
            right:-15px;
            .left-triangle{
              position:absolute;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 55px 42px 0 0;
              border-color: $colorful transparent transparent transparent;
              left:0px;
              top:0px;
            }
            li{
              display: inline-block;
              margin: 0 $link-padder;
              a{
                display: block;
                margin: $link-padder;
                padding: 0;
                display: block;
                padding:0.6em 0;
                @include opacity(1);
                &:hover ,&:focus{
                  @include opacity(0.5);
                }
              }
              &:last-child{
                margin-right:0;
                a{margin-right:0;}
              }
            }
          }
        }

      }
    }
  }
}
#main-content{margin-top:em(150);}
