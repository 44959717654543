/*
 ____             _ _
/ ___| _ __  _ __(_) |_ ___  ___
\___ \| '_ \| '__| | __/ _ \/ __|
 ___) | |_) | |  | | ||  __/\__ \
|____/| .__/|_|  |_|\__\___||___/
      |_|
--------------------------------------------------------
Generate by: Sketch CSS Sprite Mixin
Download here:
https://github.com/littlebusters/Sketch-CSS-Sprite-Mixin
--------------------------------------------------------
*/

$myUrl : "/assets/img/frontend/icons/sprites";

@mixin cssSprite( $spriteVals ) {
  width: nth( $spriteVals, 1 );
  height: nth( $spriteVals, 2 );
  background-repeat: no-repeat;
  background-image: url( nth( $spriteVals, 3 ) );
  background-position: nth( $spriteVals, 4 ) nth( $spriteVals, 5 );
  @media only screen and ( -webkit-min-device-pixel-ratio: 2 ), only screen and ( min-device-pixel-ratio: 2 ) {
    background-image: url( nth( $spriteVals, 6 ) );
    background-size: $bgiSizeW $bgiSizeH;
  }
}
$spritesPath: $myUrl;
$spritesURL: $spritesPath + '.png';
$spritesx2URL: $spritesPath + '@2.png';
$bgiSizeW: 500px;
$bgiSizeH: 500px;
$linkedin-light: 27px 27px $spritesURL -18px -65px $spritesx2URL;
$facebook-light: 11.654586758682228px 25.093718793342575px $spritesURL -86px -66px $spritesx2URL;
$twitter-light: 22.258658795996887px 17.97642002674856px $spritesURL -141px -71px $spritesx2URL;
$white-arrow: 13px 21px $spritesURL -219px -16px $spritesx2URL;
$arrow: 13px 21px $spritesURL -183px -16px $spritesx2URL;
$linkedin: 24px 24px $spritesURL -62px -14px $spritesx2URL;
$facebook: 9.970000267028809px 21.60599617990097px $spritesURL -112px -14px $spritesx2URL;
$twitter: 21.139999389648438px 17.183837715987757px $spritesURL -149px -18px $spritesx2URL;
$letter: 20.5px 15.185225728155316px $spritesURL -19px -16px $spritesx2URL;



/*
 __  __         _
|  \/  |_   _  (_) ___ ___  _ __  ___
| |\/| | | | | | |/ __/ _ \| '_ \/ __|
| |  | | |_| | | | (_| (_) | | | \__ \
|_|  |_|\__, | |_|\___\___/|_| |_|___/
        |___/
---------------------------------------------
You can add the styles for your sprites here.
---------------------------------------------
*/
i.icons{
  display:inline-block;
  &.facebook{
    @include cssSprite($facebook);
    margin:0 5px;
  }
  &.twitter{
    @include cssSprite($twitter);
    margin:0 5px;
  }
  &.linkedin{
    @include cssSprite($linkedin);
    margin:0 5px;
  }
  &.letter{@include cssSprite($letter);}
  &.arrow-left{
    @include cssSprite($arrow);
  }
  &.arrow-right{
    @include cssSprite($arrow);
    @include rotate(180deg);
  }
  &.white-arrow-left{@include cssSprite($white-arrow);}
  &.white-arrow-right{
    @include cssSprite($white-arrow);
    @include rotate(180deg);
  }
  &.white-arrow-right{
    @include cssSprite($white-arrow);
    @include rotate(180deg);
  }
  &.facebook-light{@include cssSprite($facebook-light);}
  &.linkedin-light{@include cssSprite($linkedin-light);}
  &.twitter-light{@include cssSprite($twitter-light);}


}
