/*
   ____ _       _           _
  / ___| | ___ | |__   __ _| |
 | |  _| |/ _ \| '_ \ / _` | |
 | |_| | | (_) | |_) | (_| | |
  \____|_|\___/|_.__/ \__,_|_|
*/

p{text-align:justify;}

.entete-global{
  position: relative;
  color: $light-color;
  h1,h2,h3,h4,h5,h6,p{
    color: $light-color;
  }
}
.big-content{
  padding:7em 0;
}
.arrows{
  position:absolute;
  z-index:100;
  width:em(47);
  height:em(90);
  border-radius:5px;
  top:calc(50% - 45px);
  cursor:pointer;
  text-align:center;
  border:1px solid rgba($light-color,0.4);
  &.solution{
    background-color:rgba($colorful03,0.3);
    &:hover{
      border:none;
      background-color:$dark-color;
    }
    &.left{left:-3%;}
    &.right{right:-3%;}
  }
  &.index{
    background-color:none;
  }
  i.icons{
    position:relative;
    top:calc(50% - 10px);
  }
  &:hover{
    border:1px solid $light-color;
  }
  &.left{left:3%;}
  &.right{right:3%;}
}
.slick-dots{
  bottom:0px;
  li{
    width:66px;
    height:3px;
    overflow:hidden;
    button {
      border-radius: 0px;
      padding:1.5px 33px;
      background:rgba(255,255,255,0.5);
      &:before{content:"";}
    }
    &.slick-active{
      button{background:#FFF;}
    }
  }
}
.fsc-container{
  border-radius:6px;
  overflow:hidden;
  &.green{
    max-width:80px;
    margin-left:30px;
    float:right;
  }
  img{
    max-width:80px;
    border-radius:6px;
  }
}

/*
  _____       _                       _
 | ____|_ __ | |_ _ __ ___ _ __  _ __(_)___  ___
 |  _| | '_ \| __| '__/ _ \ '_ \| '__| / __|/ _ \
 | |___| | | | |_| | |  __/ |_) | |  | \__ \  __/
 |_____|_| |_|\__|_|  \___| .__/|_|  |_|___/\___|
                          |_|
*/
.entreprise{
  .text-section{
    h2{font-size:$size-mega;}
    p{font-size:$size-medium;}
  }
  .cardboard-robot{
    max-width:95%;
    max-height:100%;
    position:absolute;
    bottom:0;
  }
}
.mission{
  .content{
    &.big{
      padding:em(125) 0;
    }
  }
  .band{
    h3{
      font-size:$size-ultra;
      margin-top:0;
    }
    p{margin-bottom:0;}
    &.grey{background-color:$lightgrey;}
  }
}
.value-box{
  margin-bottom:2em;
  p{font-size:$size-medium;}
}

/*
  ____        _       _   _
 / ___|  ___ | |_   _| |_(_) ___  _ __  ___
 \___ \ / _ \| | | | | __| |/ _ \| '_ \/ __|
  ___) | (_) | | |_| | |_| | (_) | | | \__ \
 |____/ \___/|_|\__,_|\__|_|\___/|_| |_|___/
*/
.solution-nav{
  background-color:$colorful;
  &.fixed{
    position:fixed;
    width:100%;
    top:150px;
    z-index:999;
    border-top:1px solid #638B1B;
  }
  ul{
    list-style:none;
    padding:0;
    margin:0;
    text-align:center;
    li{
      display:inline-block;
      border-right:1px solid #638B1B;
      @include transition-all(300ms);
      &:first-child{
        border-left:1px solid #638B1B;
      }
      a{
        display:block;
        padding:em(17) em(55);
        color:$light-color;
        font-weight:$fw-bold;
        font-size:$size-medium;
      }
      &:hover{
        background-color:#638B1B;
      }
    }
  }
}
.solutions{
  .band{
    h2{
      font-size:$size-big;
      margin-top:0;
    }
    ul{
      padding-left:17px;
      li{margin-bottom:7px;}
    }
    p{margin-bottom:20px;}
    &:nth-child(even){background-color:$lightgrey;}
  }
  img{max-width:100%;}
}

.box-engagement{
  background-color:#EDF5DA;
  .mascotte-environnement{max-width:100%;}
  p{color:#465a24;}
  h2{color:$colorful02;}
  .logo-fsc{
    max-width:80px;
    margin-left:30px;
    float:right;
  }
}


/*
  ____                _       _ _
 |  _ \ _ __ ___   __| |_   _(_) |_ ___
 | |_) | '__/ _ \ / _` | | | | | __/ __|
 |  __/| | | (_) | (_| | |_| | | |_\__ \
 |_|   |_|  \___/ \__,_|\__,_|_|\__|___/
*/
.product-detail-container{
  h1{
    font-size:$size-mega;
  }
  p.description{
    margin-bottom:em(65);
    font-size:$size-medium;
  }
  .main-picture,.thumbnail{
    img{max-width:100%;}
  }
  .thumbnail{
    margin-top:3em;
    a{
      display:block;
      &:hover{
        opacity:0.7;
      }
    }
  }
  .tableau{
    .row{margin-bottom:em(5);}
    .cell-block{
      background-color:$lightgrey;
      .cell{
        color:$dark-color;
        display:block;
        padding:1em 2em;
        height:100%;
      }
    }
  }
  .btn-box{
    margin-top:em(40);
    a.btn{
      width:80%;
    }
  }
  .bottom-product-text{
    margin-top:em(45);
    padding-top:em(40);
    border-top:1px solid #EAE6E0;
    p{color:$lightergrey;}
  }
}

/*GENERAL*/
a.btn.top-spacing{margin-top:1.5em;}

/*
   ____                _  __
  / ___|__ _ _ __ _ __(_) \_\ _ __ ___
 | |   / _` | '__| '__| |/ _ \ '__/ _ \
 | |__| (_| | |  | |  | |  __/ | |  __/
  \____\__,_|_|  |_|  |_|\___|_|  \___|
*/
.carriere-top-block{
  position:relative;
  height:275px !important;
  h1,h2{
    color:$light-color;
    text-align:center;
    font-size:$size-large;
    width:30%;
    margin:0 auto;
    padding:em(15) 0;
    background-color:rgba($dark-color,0.75);
    font-size:$size-mega;
  }
}
.poste{
  background-color:$lightgrey;
  .content{
    padding:6em 0;
  }
  h2{
    font-size:$size-big;
    margin-top:0;
  }
  p{margin-bottom:2em;}
}
.formulaire-emploi{
  h3{
    font-size:$size-big;
    margin-top:0;
    span{font-size:$size-xsmall;}
  }
  p.mandatory-title{
    border-bottom:1px solid #CCCCCC;
    color:$dark-color;
    padding-bottom:em(20);
    margin-bottom:em(20);
  }
  .form-box{
    .form-section{
      padding-bottom:1em;
      border-bottom:1px solid #CCCCCC;
      &.no-border{border-bottom:none;}
      label{
        position:relative;
        top:4px;
        &.left-spacing{
          margin-left:em(30);
        }
      }
      input{
        border-radius:0;
        width:100%;
      }
      textarea{height:em(65);}
    }
    a.btn{
      margin-top:1em;
      text-transform:uppercase;
    }
  }
}

/*
  _____                            _                       _
 |_   _|   _ _ __   ___  ___    __| | ___    ___ __ _ _ __| |_ ___  _ __  ___
   | || | | | '_ \ / _ \/ __|  / _` |/ _ \  / __/ _` | '__| __/ _ \| '_ \/ __|
   | || |_| | |_) |  __/\__ \ | (_| |  __/ | (_| (_| | |  | || (_) | | | \__ \
   |_| \__, | .__/ \___||___/  \__,_|\___|  \___\__,_|_|   \__\___/|_| |_|___/
       |___/|_|
*/
.list-title{
  margin-top:1em;
  display:block;
  font-weight:$fw-bold;
}
ul.spec-list{
  margin-top:0;
}
.type-carton-pict-cont{
  width:80%;
  max-width:100%;
  img{height:300px;}
}
.eska-block{
  margin-top:1em;
  display:block;
  img{width:100px;}
}
