/*
 _____                 _   _
|  ___|   _ _ __   ___| |_(_) ___  _ __  ___
| |_ | | | | '_ \ / __| __| |/ _ \| '_ \/ __|
|  _|| |_| | | | | (__| |_| | (_) | | | \__ \
|_|   \__,_|_| |_|\___|\__|_|\___/|_| |_|___/

*/


/*
--CONVERT PX INTO EM--

Usage: font-size: em(32);
32 = The size in PX
$browser-context = The Ratio
*/
$browser-context: 16;
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}
