/*
                 _       _     _
__   ____ _ _ __(_) __ _| |__ | | ___  ___
\ \ / / _` | '__| |/ _` | '_ \| |/ _ \/ __|
 \ V / (_| | |  | | (_| | |_) | |  __/\__ \
  \_/ \__,_|_|  |_|\__,_|_.__/|_|\___||___/

*/

// Media values
$value-smaller: 22em;
$value-mobile: 34em;
$value-tablet: 48em;
$value-desktop: 62em;
$value-desktop-hd: 84em;

// Containers width
$container-smaller: 100%;
$container-mobile: 100%;
$container-tablet: 90%;
$container-desktop: ($value-desktop);
$container-desktop-hd: ($value-desktop-hd)-6;

// Media queries breakpoints
$media-smaller: "(max-width: 480px)";
$media-mobile: "(max-width: 767px)";
$media-tablet: "(min-width: 768px) and (max-width: 991px)";
$media-tablet-less: "(max-width: 991px)";
$media-desktop: "(min-width: 992px) and (max-width: 1199px)";
$media-desktop-hd: "(min-width: 1200px)";

// Box grid
$box-xs: 220px;
$box-sm: 340px;
$box-md: 480px;
$box-lg: 620px;
$box-xlg: 840px;

// Layout vars
$img-frontend: "/assets/img/frontend";
$bg-icon-set: url("/assets/img/frontend/icons/icon-set.png") no-repeat;
$spacer: 15px;
$padder: $spacer*2;
$border-radius: 4px;
$border-radius-round: 500px;

$base-time: 0.3s;

// Fonts typo
$typo: 'Lato', sans-serif;
$titleTypo: 'Lato', sans-serif;

// Colors
$fb-color: #3b5998;
$gp-color: #dd4b39;

// Fonts colors
$light-color: #FFF;
$dark-color: #000;
$base-color: #343531;
$copy-color: #A6A6A6;

// Buttons colors
$colorful: #A5CE38; 
$colorful02: #77A02E;
$colorful03: #3F3122;
$colorful04: #1D1A1B;

$btn-color: $colorful;
$btn-color02: $colorful02;
$btn-color03: $colorful03;
$fill-btn-color: $light-color;
$btn-color-light: $light-color;
$fill-btn-color-light: $base-color;

// Forms colors
$errors-color: #d50000;
$success-color: #32B100;
$color-mandatory: red;

// Customs colors
$reserved-color: $colorful;
$na-color: $colorful;

// Borders
$border-size: 1px;
$border-btn-size: 2px;
$border-color: #DCDCDC;

// Basics colors
$page-background: #FFF;
$lightgrey: #EDF1FA;
$lightergrey: #7B746C;
$softgrey: #E9EAE4;
$darkgrey: #575C67;
$dark: #1D1A1B;

// Layout colors
$bg-html: $darkgrey;
$bg-header: $bg-html;
$bg-footer: $bg-html;

// Fonts weights
$fw-xlight: 100;
$fw-light: 300;
$fw-normal: 400;
$fw-medium: 400;
$fw-bold: 700;
$fw-xbold: 900;

// Fonts sizes
$size-xsmall: 0.7em;
$size-smaller: 0.8em;
$size-small: 0.9em;
$size-regular: 1em;
$size-medium: 1.2em;
$size-med-large: 1.35em;
$size-large: 1.5em;
$size-larger: 1.8em;
$size-big: 2em;
$size-ultra: 2.5em;
$size-mega: 3em;
$size-giga: 4em;
