/*
 _____           _
|  ___|__   ___ | |_ ___ _ __
| |_ / _ \ / _ \| __/ _ \ '__|
|  _| (_) | (_) | ||  __/ |
|_|  \___/ \___/ \__\___|_|

*/

footer#main-footer {
  background: $bg-footer;
  .logo-afmq{
    width:100px;
    margin-top:1em;
  }
  .entete{
    padding:em(55) em(35);
    border-bottom:1px solid rgba($light-color,0.16);
  }
  .inner{
    padding: $spacer*2 0 $spacer;
  }
  p.title{
    font-weight:$fw-bold;
    font-size:$size-medium;
    margin-bottom:0;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li{
      a {
        display: inline-block;
        text-decoration: none;
        font-size: 0.95em;
        font-weight: $fw-medium;
        font-size:$size-regular;
        padding: 0.3em 0;
        color: $light-color;
        &:hover, &:focus {
          color: $colorful;
        }
      }
    }
  }
  a.tel{
    display:block;
    font-weight:$fw-xbold;
    font-size:$size-large;
    color:$colorful;
    &:hover{color:$colorful02;}
  }
  address, p{
    font-size: $size-small;
    color: $light-color;
    padding: 0.75em 0;
  }
  .footer-social-box{
    margin-top:em(10);
    i.icons{
      margin:0 em(20);
      @include transition-all(300ms);
      &:first-child{margin-left:0;}
      &:hover{opacity:0.7;}
    }
  }
  .bottom-footer{
    background-color:rgba($dark-color,0.1);
    margin-top:3em;
    color: $copy-color;
    padding-top:2em;
    @include opacity(0.5);
    a, p {
      color: $light-color;
      font-size:$size-smaller;
    }
    a {
      display:inline-block;
      width: 64px;
      position: relative;
      top: -9px;
      margin-left: 5px;
    }
  }
}
