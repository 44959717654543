/*
  ____            _             _
 / ___|___  _ __ | |_ __ _  ___| |_
| |   / _ \| '_ \| __/ _` |/ __| __|
| |__| (_) | | | | || (_| | (__| |_
 \____\___/|_| |_|\__\__,_|\___|\__|

*/

.postes{
  background-color:$light-color;
  margin-top:2em;
  .poste{
    background-color:$light-color;
    padding:0.5em 0.5em 0.5em 0;
    border-bottom:1px solid $lightgrey;
    a{
      color:$colorful;
      font-size:$size-medium;
      &:hover{color:$dark-color;}
    }
    p{
      margin:0;
      span{
        &.tel{
          padding-top:0.5em;
          font-weight:$fw-bold;
        }
      }
    }
  }
}

.contact-robot{
  position:absolute;
  right:0;
  max-height:100%;
  width:30%;
  top:18%;
}
.contact-contents{
  padding:0 em(45);
}
.contact-box{
  background-color:$lightgrey;
  height:100%;
  h2{margin-bottom:1.5em;}
  &::after{
    content:"";
    position:absolute;
    width:800px;
    height:100%;
    background-color:$lightgrey;
    right:-800px;
    top:0px;
  }
  form .row label{
    margin-bottom:0.5em;
    display:block;
  }
  form .row input{width:50%;}
  form .row input,form .row textarea{
    border-radius:0px;
    border:1px solid #000;
    background-color:rgba(0,0,0,0);
  }
}
section.map{
  position:relative;
  #map-canvas{
    width:100%;
    height:420px;
  }
}
