///* ==========================================================================
//   Print styles.
//   Inlined to avoid required HTTP connection: h5bp.com/r
//   ========================================================================== */

@media print,
      (-o-min-device-pixel-ratio: 5/4),
      (-webkit-min-device-pixel-ratio: 1.25),
      (min-resolution: 120dpi) {
   //* Style adjustments for high resolution devices */
}

@media print {
    * {
        background: transparent !important;
        color: #000 !important; //* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; //* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}
