@media #{$media-desktop} {
  .tablet-only{display:block;}

  .estimation-tablet{
    position:absolute;
    margin:20.5px 0;
    right:95px;
  }

  header .contact-section{display:none;}
  header#main-header .bottom-bar{
    visibility:hidden;
    max-height:0px;
    .container{width:100%;}
    .left-triangle{display:none !important;}
    nav#main-nav .nav .right-block ul.socials{display:none;}
    ul li a{
      padding-left:0;
      padding-right:0;
    }
  }
  nav#main-nav .nav .right-block{
    display:block;
    width:100%;
  }
  header#main-header .bottom-bar nav#main-nav .nav .right-block{
    width:100%;
    display:block;
    .green-menu{
      padding-left:0;
      display:block;
      width:100%;
      height:100%;
      right:0px;
      .green-block,ul,li{
        display:block;
        width:100%;
        a{color:$light-color !important;}
      }
    }
  }
  nav#main-nav .nav .left-block{
    display:block;
    width:100%;
    .main{
      width:100%;
      li{
        display:block;
        text-align:center;
        width:100%;
        margin:0;
        a{
          padding-left:0;
          padding-right:0;
        }
      }
    }
  }
  header#main-header{
    .top-bar{
      .mobile-menu-icon{
        display:block;
        width:40px;
      }
    }
  }

  #main-content{margin-top:95px;}
  

/*
  ___           _
 |_ _|_ __   __| | _____  __
  | || '_ \ / _` |/ _ \ \/ /
  | || | | | (_| |  __/>  <
 |___|_| |_|\__,_|\___/_/\_\
*/
.slider-index .slide .text-block{
  top:calc(50% - 135px);
  width:80%;
  margin:0 10%;
}
}
