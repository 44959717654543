/*
               _   _
 ___  ___  ___| |_(_) ___  _ __  ___
/ __|/ _ \/ __| __| |/ _ \| '_ \/ __|
\__ \  __/ (__| |_| | (_) | | | \__ \
|___/\___|\___|\__|_|\___/|_| |_|___/

*/

#main-content{
  background: $page-background;
}

.main-page{
  background: $page-background;
}

.content{
  padding: 2.75em 0;
}

.section{
  margin-bottom: 3.5em;
}

.head-section{
  text-align: center;
  &.left{
    text-align: left;
  }
  &.right{
    text-align: right;
  }

  .title{
    color: $base-color;
    margin: 0;
    padding: 0;
    font-size: $size-ultra;
  }
  .subtitle{
     margin: $spacer 0 0;
     padding: 0;
     font-size: $size-large ;
  }
}
