/*
           _      _
 _ __ ___ (_)_  _(_)_ __  ___
| '_ ` _ \| \ \/ / | '_ \/ __|
| | | | | | |>  <| | | | \__ \
|_| |_| |_|_/_/\_\_|_| |_|___/

*/

@mixin transition-transform($args) {
  -webkit-transition: transform $args;
  -moz-transition: transform $args;
  -ms-transition: transform $args;
  -o-transition: transform $args;
  transition: transform $args;
}

@mixin transition-all($args) {
  -webkit-transition: all $args;
  -moz-transition: all $args;
  -ms-transition: all $args;
  -o-transition: all $args;
  transition: all $args;
}

@mixin opacity($args) {
  // IE 8
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=$args*100)";
  // IE 5-7
  filter: alpha(opacity=$args*100);
  // Real Browsers
  -webkit-opacity: $args;
  -moz-opacity: $args;
  -khtml-opacity: $args;
  opacity: $args;
}

@mixin rotate($args) {
  -ms-transform: rotate($args); /* IE 9 */
  -webkit-transform: rotate($args); /* Chrome, Safari, Opera */
  transform: rotate($args);
}
