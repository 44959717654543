// PUT YOUR SPECIAL MEDIAS HERE !

@media screen and (max-width: 1265px) {
  header#main-header .bottom-bar nav#main-nav .nav ul.main li a{padding:.95em 7px;}
}

@media screen and (max-width: 400px) {

  .contact-robot{
    display:none;
  }

}
