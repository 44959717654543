@media #{$media-tablet-less} {

  .tablet-only{display:block;}

  .estimation-tablet{
    position:absolute;
    margin:9.5px 0;
    right:95px;
    padding:0.5em 1.4em;
    .icons.letter{margin-left:10px;}
  }

  header .contact-section{display:none;}
  header#main-header .bottom-bar{
    visibility:hidden;
    max-height:0px;
    .container{width:100%;}
    .left-triangle{display:none !important;}
    nav#main-nav .nav .right-block ul.socials{display:none;}
    nav#main-nav .nav ul.main li{
      margin:0;
      a{
        padding-left:0;
        padding-right:0;
      }
    }
  }
  header#main-header .bottom-bar nav#main-nav .nav .right-block{
    width:100%;
    display:block;
    .green-menu{
      padding-left:0;
      display:block;
      width:100%;
      height:100%;
      right:0px;
      .green-block,ul,li{
        display:block;
        width:100%;
        a{color:$light-color !important;}
      }
    }
  }
  nav#main-nav .nav .left-block{
    display:block;
    width:100%;
    .main{
      width:100%;
      li{
        display:block;
        text-align:center;
        width:100%;
        margin:0;
        a{
          padding-left:0;
          padding-right:0;
        }
      }
    }
  }
  header#main-header{
    &.opened{
      .top-bar .mobile-menu-icon{
        width:40px;
      }
      .bottom-bar{
        top:64.25px !important;
      }
    }
    .top-bar{
      height:3.9375em;
      .logo{
        margin:0;
        width:135px;
        padding:7.5px 0;
        a{padding:0;}
      }
      .mobile-menu-icon{
        display:block;
        width:40px;
      }
    }
  }

  #main-content{margin-top:3.9375em;}
  /*
    _____           _
   |  ___|__   ___ | |_ ___ _ __
   | |_ / _ \ / _ \| __/ _ \ '__|
   |  _| (_) | (_) | ||  __/ |
   |_|  \___/ \___/ \__\___|_|
  */
  .environnement .fsc-box{
    width:90%;
  }
  /*
    ___           _
   |_ _|_ __   __| | _____  __
    | || '_ \ / _` |/ _ \ \/ /
    | || | | | (_| |  __/>  <
   |___|_| |_|\__,_|\___/_/\_\
  */
  .arrows.index.slick-arrow,.slick-dots,.arrows.solution.slick-arrow{display:none !important;}
  .slider-index .slide .text-block{
    top:calc(50% - 135px);
    width:80%;
    margin:0 10%;
    h1{
      font-size:$size-big;
      margin-top:0.8em;
    }
    .subtitle{
      font-size:$size-larger;
      line-height:1.1em;

    }
  }
  .inside-text-section.left{
    &::after{
      content:"";
      position:absolute;
      width:800px;
      height:100%;
      background:url(/assets/img/frontend/backgrounds/carton-bg.jpg);
      background-size:cover;
      right:-800px;
      top:0px;
    }
  }
  .inside-text-section.right{
    padding-left:0 !important;
    &::before{
      content:"";
      position:absolute;
      width:800px;
      height:100%;
      background-color:#EDF1FA;
      left:-800px;
      top:0;
    }
  }
  .company .text-section .inside-text-section.left{padding-right:0;}
  .section-solution .slider-solution{height:300px;}

/*
  ____        _       _   _
 / ___|  ___ | |_   _| |_(_) ___  _ __  ___
 \___ \ / _ \| | | | | __| |/ _ \| '_ \/ __|
  ___) | (_) | | |_| | |_| | (_) | | | \__ \
 |____/ \___/|_|\__,_|\__|_|\___/|_| |_|___/
*/
  .text-block.no-btn{
    height:12em !important;
  }
  .solution-nav.fixed{
    position:relative;
    top:0px;
    z-index:100;
  }
  .solutions{
    .band{
      &.white{img{margin-bottom:1em;}}
      &.grey{img{margin-top:1em;}}
    }
  }
  .service-solution-picture{
    width:70%;
    max-width:100%;
    margin:2em 15%;
  }

  /*
     ____                _  __
    / ___|__ _ _ __ _ __(_) \_\ _ __ ___
   | |   / _` | '__| '__| |/ _ \ '__/ _ \
   | |__| (_| | |  | |  | |  __/ | |  __/
    \____\__,_|_|  |_|  |_|\___|_|  \___|
  */
  .formulaire-emploi .form-box .form-section label.left-spacing{margin-left:0;}
  .formulaire-emploi .form-box .form-section label{
    margin-bottom:0.5em;
    display:block;
  }
  .contact-box{
    &::before{
      content:"";
      position:absolute;
      width:800px;
      height:100%;
      background-color:$lightgrey;
      left:-800px;
      top:0px;
    }
  }
  /*
     ____            _             _
    / ___|___  _ __ | |_ __ _  ___| |_
   | |   / _ \| '_ \| __/ _` |/ __| __|
   | |__| (_) | | | | || (_| | (__| |_
    \____\___/|_| |_|\__\__,_|\___|\__|
  */
  .contact-contents{padding:0;}
  .contact-robot{right:-6%;}

}
