/*
 ____  _
| __ )| | ___   __ _ _   _  ___
|  _ \| |/ _ \ / _` | | | |/ _ \
| |_) | | (_) | (_| | |_| |  __/
|____/|_|\___/ \__, |\__,_|\___|
               |___/
*/

.blogue{
  background-color:$lightgrey;
  padding:1em 0;
  h1.title{
    margin-top:0px;
    font-size:$size-medium;
    color:$colorful02;
  }
  .text-blog{
    background-color:#FFF;
    padding:2.5em 5%;
  }
  .bordered-text{
    border-bottom:1px solid $colorful02;
    padding-bottom:0.5em;
    margin-bottom:1em;
    margin-top:1.5em;
  }
  .right-side{
    display:block;
    overflow:hidden;
    height:auto;
    h3{font-size:1.5em;}
    ul{
      list-style:none;
      padding-left:0px;
      li{
        padding:0.8em 0;
        border-bottom:1px solid rgba(132,39,71,0.3);
        a{
          display:inline-block;
          width:100%;
        }
      }
    }
  }
  .blogs_list{
    list-style:none;
    padding-left:0px;
    margin: 0;
    position:relative;
    height:100%;
    float:left;
    max-width:none;
    div{
      margin-bottom: 0.5em;
      a{color:$colorful02;}
      .blog-picture{
        position:relative;
        width:100%;
      }
      .blog-content{
        background-color:#FFF;
        padding:2em;
        border-top:4px solid $colorful02;
        text-align:left;
        .date{
          font-size:0.8em;
          margin:0;
          font-weight:bold;
          margin-top:1em;
          margin-bottom:0;
        }
        p{margin:1em 0;}
        .author,.hour{
          font-size:0.8em;
          margin:0;
          font-weight:bold;
          margin-bottom:0;
        }
        .author{color:#818181;}
        .keywords{
          font-size:0.8em;
          a{font-size:1em;}
        }
        .share-box{
          padding-top:1em;
          margin-top:1em;
          border-top:1px solid rgba(132, 39, 71, 0.1);
          position:relative;
          .social-icon{
            padding:0 7px;
            i.fa{font-size:17px;}
          }
          .share-block{
            cursor:pointer;
            overflow:hidden;
            opacity:1;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            .text-share{
              float:right;
              margin-right:8px;
              position:relative;
              top:-2px;
              color:$colorful02;
            }
            .share-icon{
              float:right;
              height:20px;
              max-width:100%;
            }
            &:hover{
              opacity:0.4;
            }
          }
          .social-box{
            overflow:hidden;
            position:absolute;
            width:100%;
            right:-9px;
            max-height:0px;
            text-align:right;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            .social-icon{
              display:block;
            }
            &.active{
              max-height:500px;
            }
          }
        }
      }
    }
    a{
      font-weight:bold;
      font-size:1.5em;
    }
  }
  .blog-article{
    background-color:#FFF;
    padding:2em 5%;
    overflow:hidden;
    margin-bottom:2em;
    a {
      color: $colorful;
    }
    h1{
      font-size: 1.6em;
    }
    h1,h2{
      margin-top:0px;
      margin-bottom:2px;
    }
    .top-block{
      border-bottom:1px solid $colorful02;
      margin-bottom:20px;
      p{
        margin-top:5px;
        color:#999999;
        font-weight:600;
        font-size:0.8em;
      }
    }
    img{
      max-width:100%;
    }
  }
  .commentary{
    background-color:#FFF;
    padding:0.5em 3em;
  }
  .grid-sizer,
  .grid-item {
    width: 50%;
    margin:0;
    img{max-width:100% !important;}
  }
  .commentaires{
    background-color:#FFF;
    padding:1em 0;
    margin-top:0.5em;
    .comment-title{
      color:$colorful02;
    }
  }
  .right-side{
    background-color: $light-color;
    border-top: 4px solid $colorful02;
    padding: 1.5em 45px;
    margin-bottom: 1.25em;
    h3{color:$colorful02;}
    a{
      color:$colorful02;
      text-align:left;
    }
  }
}
@media #{$media-tablet} {
  .blogue .grid-item{
    width:100%;
    margin:0 auto;
  }
}
@media #{$media-mobile} {
  .blogue .grid-item{
    width:100%;
  }
  .blogue .container.blogs_list{width:100%;}
}
@media screen and (max-width: 544px) {
  .blogue .blogs_list{width:100%;}
}
