@media #{$media-mobile} {

  .desktop-only{display:none;}
  .mobile-only{display:block;}
  .tablet-only{display:none;}

  .logo{
    width:50px !important;
    padding:13px 0 !important;
  }
  .quote-header{display:none!important;}

  .slider-index .slide .text-block{
    width:100%;
    margin:0;
    h1{
      font-size:$size-big;
      margin-top:0.8em;
    }
    .subtitle{
      font-size:$size-larger;
      line-height:1.1em;

    }
  }
  .carriere-top-block h1{width:100%;}

  .environnement p.engagement span.white-bar{display:none;}
  .type-carton-pict-cont{
    width:70%;
    margin:2em 15%;
  }

  .contact-robot{
    right:0%;
  }

  .echantillon div{text-align:center !important;}
  .echantillon .mascotte-bulle{margin-bottom:1em;}


}
