/*
                  _   _       _
 _ __   __ _ _ __| |_(_) __ _| |___
| '_ \ / _` | '__| __| |/ _` | / __|
| |_) | (_| | |  | |_| | (_| | \__ \
| .__/ \__,_|_|   \__|_|\__,_|_|___/
|_|

*/

%border-radius-none {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

%border-radius {
  -webkit-border-radius: $border-radius;
  -moz-border-radius: $border-radius;
  border-radius: $border-radius;
}

%border-radius-round {
  -webkit-border-radius: $border-radius-round;
  -moz-border-radius: $border-radius-round;
  border-radius: $border-radius-round;
}

%border-radius-bottom {
  -webkit-border-bottom-right-radius: $border-radius;
  -webkit-border-bottom-left-radius: $border-radius;
  -moz-border-radius-bottomright: $border-radius;
  -moz-border-radius-bottomleft: $border-radius;
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}
